// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-component-testing-jsx": () => import("./../../../src/pages/component-testing.jsx" /* webpackChunkName: "component---src-pages-component-testing-jsx" */),
  "component---src-pages-dev-404-page-jsx": () => import("./../../../src/pages/dev-404-page.jsx" /* webpackChunkName: "component---src-pages-dev-404-page-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-sponsors-jsx": () => import("./../../../src/pages/sponsors.jsx" /* webpackChunkName: "component---src-pages-sponsors-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-team-team-bios-jsx": () => import("./../../../src/pages/team/teamBios.jsx" /* webpackChunkName: "component---src-pages-team-team-bios-jsx" */),
  "component---src-template-video-page-template-jsx": () => import("./../../../src/template/VideoPageTemplate.jsx" /* webpackChunkName: "component---src-template-video-page-template-jsx" */)
}

